import { useTheme } from '@shopify/restyle';
import { useComponentsConfig } from '@webstore-monorepo/shared/contexts/components-config-provider';
import { CalendarIcon, LockIcon } from '@webstore-monorepo/shared/icons';
import type { Theme } from '@webstore-monorepo/shared/theme';
import { Box } from '@webstore-monorepo/ui/box';
import { Button } from '@webstore-monorepo/ui/button';
import { Input } from '@webstore-monorepo/ui/input';
/**
 * Import scss in webstore
 * import './birthday-picker.scss';
 */
import { Text } from '@webstore-monorepo/ui/text';
import enUS from 'date-fns/locale/en-US';
import { MaskedRange } from 'imask';
import { DateTime } from 'luxon';
import React from 'react';
import ReactDatePicker from 'react-datepicker';
import { isIOS } from 'react-device-detect';
import { IMaskMixin } from 'react-imask';

type Props = {
  format: string;
  isBirthdayRequired: boolean;
  setBirthday: (date: Date | null) => void;
  setTouched?: () => void;
  maxBirthdayDate: Date;
  startBirthdayDate: Date;
  value?: Date;
  error: string;
  touched: boolean;
  hasError?: boolean;
  disabled?: boolean;
};

const CustomInput = ({ onClick, hasError }: any) => {
  const styles = hasError ? {} : {};

  return <Button variant="link" {...styles} onPress={onClick} leftIcon={<CalendarIcon fill="#666" stroke="#666" strokeWidth={0} />} />;
};

const DEFAULT_FORMAT = 'yyyy-MM-dd';

const dateMask = {
  mask: Date, // enable date mask

  // other options are optional
  pattern: 'm{/}`d{/}`Y', // Pattern mask with defined blocks, default is 'd{.}`m{.}`Y'
  // you can provide your own blocks definitions, default blocks for date mask are:
  blocks: {
    d: {
      mask: MaskedRange,
      from: 1,
      to: 31,
      maxLength: 2,
      placeholderChar: 'D',
    },
    m: {
      mask: MaskedRange,
      from: 1,
      to: 12,
      maxLength: 2,
      placeholderChar: 'M',
    },
    Y: {
      mask: MaskedRange,
      from: 1900,
      to: 9999,
      placeholderChar: 'Y',
    },
  },

  autofix: true, // defaults to `false`
  unmask: 'typed' as any, // weird error with typescript

  // also Pattern options can be set
  lazy: false,

  // and other common options
  overwrite: true, // defaults to `false`
};

const BirthdayInput = IMaskMixin(({ inputRef, ...props }) => (
  // @ts-ignore
  <Input
    aria-label="Birthday"
    {...props}
    ref={inputRef} // bind internal input (if you use styled-components V4, use "ref" instead "innerRef")
  />
));

export const DatePicker: React.FC<Props> = (props): React.ReactElement => {
  const { hasError, setBirthday, maxBirthdayDate, startBirthdayDate, value, format, error, touched, disabled } = props;
  const theme = useTheme<Theme>();
  const { input: inputConfig } = useComponentsConfig().sharedComponents ?? {};

  return (
    <Box>
      <Box flexDirection="row" alignItems="center" position="relative">
        {isIOS ? (
          <input
            type="date"
            className={`birthday__input birthday__input--ios ${hasError ? 'is-invalid' : ''}`}
            value={DateTime.fromJSDate(value || startBirthdayDate).toFormat(DEFAULT_FORMAT)}
            max={DateTime.fromJSDate(maxBirthdayDate).toFormat(DEFAULT_FORMAT)}
            onBlur={props.setTouched}
            onChange={(e) => {
              e.persist();
              setBirthday(DateTime.fromFormat(e.target.value, DEFAULT_FORMAT).toJSDate());
            }}
            style={{
              padding: theme.spacing.m,
              borderRadius: theme.borderRadii[inputConfig?.style?.borderRadius ?? 'xs'],
              borderWidth: inputConfig?.style?.borderWidth ?? 1,
              borderColor: theme.colors[inputConfig?.style?.borderColor ?? 'gray200'],
              color: theme.colors[inputConfig?.style?.color ?? 'black'],
              backgroundColor: theme.colors[inputConfig?.style?.backgroundColor ?? 'white'],
              maxWidth: '100%',
              height: inputConfig?.style?.height ?? '50px',
              ...(hasError && {
                // @ts-ignore - TODO: extend input error types in core-sdk
                borderColor: theme.colors[inputConfig?.error?.style?.borderColor ?? 'danger'],
                // @ts-ignore - TODO: extend input error types in core-sdk
                borderWidth: inputConfig?.error?.style?.borderWidth ?? 1,
                color: theme.colors[inputConfig?.error?.style?.color ?? 'black'],
              }),
            }}
          />
        ) : (
          <>
            <BirthdayInput
              className={`birthday__input ${hasError ? 'is-invalid' : ''}`}
              {...dateMask}
              value={value as unknown as string}
              onBlur={props.setTouched}
              testID="birthday"
              onAccept={(value: unknown) => setBirthday(value as Date)}
              // define date -> str convertion
              format={(date: Date) => DateTime.fromJSDate(date).toFormat(format)}
              // define str -> date convertion
              parse={(str: string) => DateTime.fromFormat(str, format).toJSDate()}
              // @ts-ignore
              translate={(value: any) => DateTime.fromFormat(value, format).toFormat(DEFAULT_FORMAT)}
              inputState={{
                error: !!error,
                touched: !!touched,
              }}
              backgroundColor={disabled ? inputConfig?.inactive?.style?.backgroundColor ?? 'gray100' : inputConfig?.style?.backgroundColor ?? 'white'}
              disabled={disabled}
            />
            {disabled ? (
              <Box position="absolute" right={20}>
                <LockIcon width={12} height={12} strokeWidth={0} />
              </Box>
            ) : (
              <ReactDatePicker
                selected={value || null}
                onChange={(date: Date | null) => setBirthday(date)}
                peekNextMonth
                maxDate={maxBirthdayDate}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                popperPlacement="auto"
                openToDate={value || startBirthdayDate}
                locale={enUS}
                popperModifiers={{
                  // @ts-ignore
                  offset: {
                    enabled: true,
                    offset: '-200px, -50px',
                  },
                  flip: {
                    enabled: false, // don't allow it to flip to be above
                  },
                  preventOverflow: {
                    enabled: true,
                    escapeWithReference: false,
                  },
                  hide: {
                    enabled: false, // turn off since needs preventOverflow to be enabled
                  },
                }}
                customInput={<CustomInput hasError={hasError} />}
              />
            )}
          </>
        )}
      </Box>
      {!!props.error && props.touched ? (
        <Text fontSize="xs" color="danger" mt="xs" {...inputConfig?.error?.style}>
          {props.error}
        </Text>
      ) : (
        <Text fontSize="xxs" mt="xxs" color="gray500">
          {/* {registrationStep?.birthday?.note?.options?.text ?? ''} */}
        </Text>
      )}
    </Box>
  );
};
CustomInput.displayName = 'CustomInput';
