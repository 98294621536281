import { USER_PROFILE_VALIDATION_PATTERN } from '@goparrot/customer-sdk';
import { BirthdayFieldPolicyEnum } from '@goparrot/store-v2-sdk';
import { useComponentsConfig } from '@webstore-monorepo/shared/contexts/components-config-provider';
import type { UserFields } from '@webstore-monorepo/shared/interfaces';
import { Box, Stack } from '@webstore-monorepo/ui/box';
import { DatePicker } from '@webstore-monorepo/ui/date-picker';
import { Input } from '@webstore-monorepo/ui/input';
import { PhoneInput } from '@webstore-monorepo/ui/phone-input';
import { Text } from '@webstore-monorepo/ui/text';
import type { FormikProps } from 'formik';
import { Formik } from 'formik';
import { DateTime } from 'luxon';
import type { PropsWithChildren } from 'react';
import React, { forwardRef, memo, useState } from 'react';
import type { DateSchema, StringSchema } from 'yup';
import { date, object, string } from 'yup';

import { FormikEffect } from '../FormikEffect';

export interface UserFormProps {
  birthdayFieldStatus: BirthdayFieldPolicyEnum;
  isOptionalEmailInMobileAppOnly?: boolean;
  user: UserFields;
  showErrors: boolean;
  onSubmit: (values: UserFields) => Promise<void>;
  onUserChange?: (values: UserFields) => void;
  onUserTouched?: (values: any) => void;
  userTouched?: any;
  showPhoneNumberInput?: boolean;
  phoneLabel?: string;
  setCurrentUserData?: React.Dispatch<React.SetStateAction<UserFields>>;
}

interface SignupShape {
  firstName: StringSchema<string>;
  lastName: StringSchema<string>;
  email?: StringSchema<string>;
  birthday?: DateSchema<Date> | undefined;
}

const maxBirthdayDate = DateTime.fromJSDate(new Date()).minus({ years: 12 }).toJSDate();
const startBirthdayDate = new Date('2000/01/01');
const birthDayFormat = 'MM/dd/yyyy';

const toLocalDate = (utcDate: string | Date | null | undefined, format = 'yyyy-MM-dd') => {
  if (typeof utcDate !== 'string' || utcDate === '') {
    return utcDate;
  }
  const dt = DateTime.fromFormat(utcDate, format, { zone: 'utc' });
  return new Date(dt.year, dt.month - 1, dt.day);
};

const getBirthdayShape = (maxDate: Date, isBirthdayRequired: boolean, birthDayFormat: string) => {
  const birthDayShapeForRequired = date()
    .required('Please provide your birthday')
    .max(maxDate, `Maximum date should be ${DateTime.fromJSDate(maxDate).toFormat(birthDayFormat)}`)
    .nullable(true);

  const birthDayShapeForOptional = date()
    .max(maxDate, `Maximum date should be ${DateTime.fromJSDate(maxDate).toFormat(birthDayFormat)}`)
    .nullable(true);

  return isBirthdayRequired ? birthDayShapeForRequired : birthDayShapeForOptional;
};

const getEmailShape = (isOptionalEmailInMobileAppOnly: boolean) => {
  const emailShapeForRequired = string().email('Your email address format is incorrect').max(70, 'Too Long!').required('Please provide your email').trim();

  const emailShapeForOptional = string().notRequired().email('Your email address format is incorrect').max(70, 'Too Long!').nullable(true).trim();

  return isOptionalEmailInMobileAppOnly ? emailShapeForOptional : emailShapeForRequired;
};

const SignupShape: SignupShape = {
  // @ts-ignore
  firstName: string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .matches(USER_PROFILE_VALIDATION_PATTERN, 'Sorry, only letters (A-z) are allowed')
    .required('Please provide your first name'),
  // @ts-ignore
  lastName: string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .matches(USER_PROFILE_VALIDATION_PATTERN, 'Sorry, only letters (A-z) are allowed')
    .required('Please provide your last name'),
};

export const UserForm = memo(
  forwardRef((props: UserFormProps, ref?: any) => {
    const [focusDate, setFocusDate] = useState<boolean>(false);
    const { sharedComponents, loginRegisterScreen } = useComponentsConfig() ?? {};
    const { registrationStep } = loginRegisterScreen ?? {};
    const { input } = sharedComponents ?? {};

    const handleBirthdayPickerPress = () => {
      if (focusDate) {
        return;
      }
      setFocusDate(true);
    };

    const birthdayFieldStatus = props.birthdayFieldStatus;
    const isBirthdayRequired = BirthdayFieldPolicyEnum.REQUIRED === birthdayFieldStatus;
    const isOptionalEmailInMobileAppOnly = false;

    if (BirthdayFieldPolicyEnum.DISABLED !== birthdayFieldStatus) {
      // @ts-ignore
      SignupShape.birthday = getBirthdayShape(maxBirthdayDate, isBirthdayRequired, birthDayFormat);
    }

    // @ts-ignore
    SignupShape.email = getEmailShape(isOptionalEmailInMobileAppOnly ?? false);

    const SignupSchema = object().shape({
      ...SignupShape,
    });

    const handleSubmit = async (values: UserFields) => {
      const data = { ...values };
      if (birthdayFieldStatus === BirthdayFieldPolicyEnum.DISABLED) {
        delete data.birthday;
      }
      return props.onSubmit(data);
    };

    return (
      <Formik
        enableReinitialize
        innerRef={ref}
        initialValues={{
          firstName: props.user.firstName,
          lastName: props.user.lastName,
          email: props.user.email,
          birthday: toLocalDate(props.user.birthday),
          phoneNumber: props.user.phoneNumber,
        }}
        initialTouched={props.userTouched}
        validationSchema={SignupSchema}
        validateOnChange
        validateOnBlur
        validateOnMount
        onSubmit={handleSubmit}
      >
        {({ setFieldValue, values, handleChange, errors, touched, setFieldTouched }: PropsWithChildren<FormikProps<UserFields>>) => (
          <Stack
            zIndex={1}
            direction="column"
            size="l"
            width={{
              phone: '100%',
              tablet: 320,
            }}
          >
            <FormikEffect onChange={props.onUserChange} setTouched={props.onUserTouched} values={values} touched={touched} />
            <Box>
              <Input
                testID="first-name-input"
                required
                editable
                accessibilityLabel="First Name"
                accessibilityHint={registrationStep?.firstName?.note?.options?.text ?? 'Ensure only your first name is entered here'}
                autoFocus
                onChangeText={(event: any) => {
                  handleChange('firstName')(event);
                }}
                onBlur={() => {
                  setFieldTouched('firstName');
                  setFieldValue('firstName', values.firstName.trim(), true);
                }}
                value={values.firstName}
                inputState={{
                  error: !!errors.firstName,
                  touched: !!touched.firstName,
                }}
                errorMessage={errors.firstName}
                placeholder={registrationStep?.firstName?.note?.options?.text ?? 'Ensure only your first name is entered here'}
                label={registrationStep?.firstName?.label?.options?.text ?? 'First Name'}
              />
            </Box>
            <Box>
              <Input
                testID="last-name-input"
                required
                editable
                accessibilityLabel="Last Name"
                accessibilityHint={registrationStep?.lastName?.note?.options?.text ?? 'Ensure only your last name is entered here'}
                onChangeText={(event: any) => {
                  handleChange('lastName')(event);
                }}
                onBlur={() => {
                  setFieldTouched('lastName');
                  setFieldValue('lastName', values.lastName.trim(), true);
                }}
                value={values.lastName}
                inputState={{
                  error: !!errors.lastName,
                  touched: !!touched.lastName,
                }}
                errorMessage={errors.lastName}
                placeholder={registrationStep?.lastName?.note?.options?.text ?? 'Ensure only your last name is entered here'}
                label={registrationStep?.lastName?.label?.options?.text ?? 'Last Name'}
              />
            </Box>
            <Box>
              <Input
                editable
                testID="email-input"
                accessibilityLabel="Email"
                accessibilityHint={registrationStep?.email?.note?.options?.text ?? 'Ensure only your email address is entered here'}
                autoCapitalize="none"
                keyboardType="email-address"
                onChangeText={(event: any) => {
                  handleChange('email')(event);
                }}
                onBlur={() => {
                  setFieldTouched('email');
                  setFieldValue('email', values.email?.trim(), true);
                }}
                value={values.email}
                inputState={{
                  error: !!errors.email,
                  touched: !!touched.email,
                }}
                errorMessage={errors.email}
                placeholder={registrationStep?.email?.note?.options?.text ?? 'Ensure only your email address is entered here'}
                label={registrationStep?.email?.label?.options?.text ?? 'Email'}
              />
            </Box>
            {props.showPhoneNumberInput ? (
              <Box>
                <Text color="gray700" fontSize="m" fontWeight="700" my="xs" {...input?.label?.style}>
                  Phone
                </Text>
                <PhoneInput
                  testID="phone-input"
                  disabled
                  defaultValue={values.phoneNumber}
                  onChangeText={() => {}}
                  onChangeCountry={() => {}}
                  onChangeFormattedText={() => {}}
                  onKeyPress={() => {}}
                  disableArrowIcon={true}
                  disabledCountrySelect={true}
                  hasError={false}
                />
              </Box>
            ) : null}
            {birthdayFieldStatus === BirthdayFieldPolicyEnum.DISABLED ? null : (
              <Box width="50%">
                <Text color="gray700" fontSize="m" fontWeight="700" my="xs" {...input?.label?.style} testID="birthday-label">
                  {registrationStep?.birthday?.label?.options?.text ?? 'Birthday'}
                  {isBirthdayRequired || !!values.birthday ? null : <Text> (optional)</Text>}
                </Text>
                <DatePicker
                  testID="birthday-input"
                  required={isBirthdayRequired}
                  hasError={!!errors.birthday}
                  isBirthdayRequired={isBirthdayRequired}
                  setTouched={() => setFieldTouched('birthday', true)}
                  setBirthday={(value: Date | null) => {
                    return setFieldValue('birthday', value);
                  }}
                  maxBirthdayDate={maxBirthdayDate}
                  startBirthdayDate={startBirthdayDate}
                  format={birthDayFormat}
                  value={values.birthday}
                  handleBirthDaySelect={(value) => {
                    setFieldValue('birthday', value);
                    setFocusDate(false);
                  }}
                  handleBirthdayPickerPress={handleBirthdayPickerPress}
                  handleBirthdayCancel={() => {
                    if (focusDate) {
                      setFocusDate(false);
                    }
                  }}
                  focusDate={focusDate}
                  setFieldTouched={setFieldTouched}
                  setFieldValue={setFieldValue}
                  // @ts-ignore
                  userDate={values.birthday}
                  error={errors.birthday}
                  touched={!!touched.birthday}
                  placeholder={registrationStep?.birthday?.note?.options?.text ?? ''}
                  disabled={props.user.disableBirthday}
                />
              </Box>
            )}
          </Stack>
        )}
      </Formik>
    );
  }),
);
