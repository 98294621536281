import type { BackgroundColorProps, BorderProps, ColorProps, LayoutProps, SpacingProps, TypographyProps } from '@shopify/restyle';
import { backgroundColor, border, color, createRestyleComponent, createRestyleFunction, layout, spacing, useTheme } from '@shopify/restyle';
import { useComponentsConfig } from '@webstore-monorepo/shared/contexts/components-config-provider';
import { InfoIcon } from '@webstore-monorepo/shared/icons';
import type { Theme } from '@webstore-monorepo/shared/theme';
import { Box } from '@webstore-monorepo/ui/box';
import { Text } from '@webstore-monorepo/ui/text';
import type { ChangeEvent, FocusEvent } from 'react';
import React, { forwardRef, memo, useEffect, useState } from 'react';
import type { AccessibilityProps, NativeSyntheticEvent, TextInputSubmitEditingEventData } from 'react-native';
import { Platform, TextInput } from 'react-native';

export type InputRestyle = SpacingProps<Theme> &
  TypographyProps<Theme> &
  LayoutProps<Theme> &
  BackgroundColorProps<Theme> &
  BorderProps<Theme> &
  ColorProps<Theme> &
  AccessibilityProps & {
    newStyle?: boolean;
    onChangeText?: (e: ChangeEvent<any>) => void;
    onBlur?: (e: ChangeEvent<any>) => void;
    onFocus?: (e: FocusEvent<any>) => void;
    onSubmitEditing?: (e: NativeSyntheticEvent<TextInputSubmitEditingEventData>) => void;
    value?: string;
    pointerEvents?: string;
    editable?: boolean;
    autoCapitalize?: string;
    keyboardType?: string;
    returnKeyType?: string;
    autoFocus?: boolean;
    fullWidth?: boolean;
    label?: string;
    required?: boolean;
    multiline?: boolean;
    numberOfLines?: number;
    inputState?: {
      error?: boolean;
      touched?: boolean;
    };
    errorMessage?: string;
    noteMessage?: string;
    inputErrorStyle?: Record<string, unknown>;
    inputNotesStyle?: Record<string, unknown>;
    placeholder?: string;
    placeholderTextColor?: string;
    maxLength?: number | undefined;
    style?: Record<string, unknown>;
    testID?: string;
  };

const withFontSizesThemeKey = createRestyleFunction({
  property: 'fontSize',
  styleProperty: 'fontSize',
  themeKey: 'fontSizes',
});

const withFontFamily = createRestyleFunction({
  property: 'fontFamily',
  styleProperty: 'fontFamily',
  themeKey: 'fontFamily',
});

export const InputRestyle = createRestyleComponent<InputRestyle, Theme>(
  [spacing, color, backgroundColor, layout, border, withFontSizesThemeKey, withFontFamily],
  TextInput,
);

export const Input = memo(
  forwardRef((props: InputRestyle, ref?: any) => {
    const [showLabel, setShowLabel] = useState(!!props.value);
    const [showPlaceholder, setShowPlaceholder] = useState(true);
    const theme = useTheme<Theme>();
    const { input: inputConfig } = useComponentsConfig().sharedComponents ?? {};
    const { inputState } = props;
    const label = props.newStyle ? props.label ?? props.placeholder : props.label;
    const errorStyles = inputState?.error &&
      inputState?.touched && {
        // @ts-ignore TODO: update sdk types for error object
        borderColor: inputConfig?.error?.style?.borderColor ?? 'danger',
        // @ts-ignore
        borderWidth: inputConfig?.error?.style?.borderWidth ?? 1,
        color: inputConfig?.style?.color ?? 'black',
      };

    const handleSetShowLabel = () => {
      if (props.editable) {
        setShowLabel(true);
      }
      if (Platform.OS !== 'web') {
        setShowPlaceholder(false);
      }
    };

    const handleHideLabel = (event: ChangeEvent<any>) => {
      if (props.editable) {
        setShowLabel(!!props.value);
        props?.onBlur && props.onBlur(event);
      }
      setShowPlaceholder(true);
    };

    useEffect(() => {
      if (props.value && props.editable) {
        setShowLabel(true);
      }
    }, [props.value, props.editable]);

    const messageComponent =
      inputState?.error && inputState?.touched ? (
        <Box
          nativeID={`input-error-message-${props.label?.split(' ').join('_')}`}
          {...(props.newStyle ? { flexDirection: 'row', alignItems: 'center' } : null)}
        >
          {props.newStyle ? <InfoIcon height={15} width={15} fill="danger" /> : null}
          <Text
            testID={`${props?.testID}-warning`}
            accessibilityLabel={`${props.label} has an error! ${props.errorMessage}`}
            accessibilityHint="This field has an error"
            fontSize="xs"
            color="danger"
            mt="xs"
            {...(props.newStyle ? { ml: 1 } : null)}
            {...inputConfig?.error?.style}
          >
            {props.errorMessage ?? ''}
          </Text>
        </Box>
      ) : props.noteMessage ? (
        <Box nativeID={`input-note-message-${props.label?.split(' ').join('_')}`}>
          <Text accessibilityLabel="note" accessibilityHint="field note" fontSize="xxs" mt="xxs" color="gray500" {...inputConfig?.note?.style}>
            {props.noteMessage ?? ''}
          </Text>
        </Box>
      ) : null;

    return (
      <Box {...(props.fullWidth ? { flexGrow: 1 } : { flexShrink: 1 })}>
        <Box
          height={Platform.OS === 'web' ? '100%' : 'auto'}
          flex={1}
          {...(props.newStyle
            ? {
                backgroundColor: inputConfig?.style?.backgroundColor ?? 'white',
                borderRadius: 'xs',
                borderWidth: inputConfig?.style?.borderWidth ?? 1,
                minHeight: 64,
                maxHeight: 64,
                justifyContent: 'center',
                borderColor: inputConfig?.style?.borderColor ?? 'gray200',
                px: 4,
                py: 2,
                overflow: 'hidden',
                ...errorStyles,
              }
            : null)}
        >
          {(props.newStyle ? showLabel && label : label) ? (
            <Text
              accessibilityLabel={label}
              accessibilityHint="field"
              color="gray700"
              {...(props.newStyle
                ? {
                    mb: 2,
                    fontFamily: 'secondary',
                    fontWeight: '600',
                    fontSize: 's',
                    zIndex: 1,
                  }
                : {
                    my: 'xs',
                    fontSize: 'm',
                    fontFamily: 'primary',
                    fontWeight: '700',
                  })}
              {...inputConfig?.label?.style}
            >
              {label}
            </Text>
          ) : null}
          <Box height={Platform.OS === 'web' ? '100%' : 'auto'} flex={1} justifyContent="center">
            {/** @ts-ignore InputTextRestyle issues with our sdk types not critical but required investigation */}
            <InputRestyle
              onFocus={handleSetShowLabel}
              // @ts-ignore
              accessibilityRequired={props.required}
              accessibilityInvalid={inputState?.error && inputState?.touched}
              accessibilityErrorMessage={`input-error-message-${props.label?.split(' ').join('_')}`}
              accessibilityDescribedBy={`input-note-message-${props.label?.split(' ').join('_')}`}
              accessible={true}
              {...(props.newStyle
                ? { fontWeight: '400', color: 'gray900', padding: 'none' }
                : {
                    borderWidth: 1,
                    padding: 'm',
                    height: 50,
                    borderRadius: 'xs',
                    borderColor: 'gray200',
                    color: 'black',
                  })}
              maxWidth="100%"
              // @ts-ignore
              fontSize="16"
              backgroundColor="white"
              placeholderTextColor={theme.colors.gray400}
              {...inputConfig?.style}
              {...(props.newStyle ? { borderColor: 'transparent', borderWidth: 0 } : {})}
              {...(!props.newStyle && errorStyles)}
              ref={ref}
              editable={props.editable}
              {...props}
              style={{
                ...(Platform.OS === 'web' ? { outline: 'none' } : {}),
                ...props.style,
              }}
              onBlur={handleHideLabel}
              placeholder={showPlaceholder ? props.placeholder : ''}
            />
            {!props.newStyle ? messageComponent : null}
          </Box>
        </Box>
        {props.newStyle ? messageComponent : null}
      </Box>
    );
  }),
);
